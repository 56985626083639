import { FC } from 'react';
import { ISvgProps } from 'shared/svg/interfaces';

const defaultProps: ISvgProps = {
  viewBox: '0 0 93 94',
  pathClassName: 'st0',
};

export const SvgIconCheckCircle: FC<ISvgProps> = (props) => {
  props = { ...defaultProps, ...props };
  const { pathClassName, ...svgProps } = props;

  return (
    <svg {...svgProps}>
      <path
        className={pathClassName}
        d="M46.5 0.5C38.125 0.5 30.375 2.625 23.25 6.875C16.125 11 10.4375 16.625 6.1875 23.75C2.0625
                30.875 0 38.625 0 47C0 55.375 2.0625 63.125 6.1875 70.25C10.4375 77.375 16.125 83 23.25 87.125C30.375
                91.375 38.125 93.5 46.5 93.5C54.875 93.5 62.625 91.375 69.75 87.125C76.875 83 82.5 77.375 86.625 70.25C90.875
                63.125 93 55.375 93 47C93 38.625 90.875 30.875 86.625 23.75C82.5 16.625 76.875 11 69.75 6.875C62.625 2.625 54.875
                0.5 46.5 0.5ZM46.5 87.5C39.25 87.5 32.5 85.6875 26.25 82.0625C20 78.4375 15.0625 73.5625 11.4375 67.4375C7.8125 61.1875
                6 54.4375 6 47.1875C6 39.8125 7.8125 33 11.4375 26.75C15.0625 20.5 19.9375 15.5625 26.0625 11.9375C32.3125 8.3125 39.0625
                6.5 46.3125 6.5C53.6875 6.5 60.5 8.3125 66.75 11.9375C73 15.5625 77.9375 20.5 81.5625 26.75C85.1875 32.875 87 39.625 87
                47C87 54.25 85.1875 61 81.5625 67.25C77.9375 73.5 73 78.4375 66.75 82.0625C60.625 85.6875 53.875 87.5 46.5 87.5ZM73.125
                35.9375L39.1875 69.5C38.8125 70 38.3125 70.25 37.6875 70.25C37.0625 70.25 36.5 70 36 69.5L19.875 53.1875C19.5 52.8125
                19.3125 52.3125 19.3125 51.6875C19.3125 51.0625 19.5 50.5 19.875 50L21.5625 48.5C21.9375 48.125 22.4375 47.9375 23.0625
                47.9375C23.6875 47.9375 24.25 48.125 24.75 48.5L37.6875 61.625L68.25 31.0625C68.75 30.6875 69.3125 30.5 69.9375
                30.5C70.5625 30.5 71.0625 30.75 71.4375 31.25L73.125 32.75C73.5 33.25 73.6875 33.8125 73.6875 34.4375C73.6875 35.0625 73.5
                35.5625 73.125 35.9375Z"
      />
    </svg>
  );
};
