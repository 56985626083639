import { combineReducers } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';

import ApiEndpoints from '_api/Endpoints/ApiEndpoints';

const reducer = combineReducers({
  apiEndpoints: ApiEndpoints.reducer,
});

export const middleware = [thunkMiddleware, ApiEndpoints.middleware];

export default reducer;
