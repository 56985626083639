import { Heading5 } from 'components';
import './_header.scss';
import { FC } from 'react';

type IHeaderProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
>;

export const Header: FC<IHeaderProps> = (props) => {
  const { children } = props;
  const baseClassName = 'header';

  return (
    <header className={baseClassName}>
      <Heading5 className={`${baseClassName}__title`}>
        <strong>Byr</strong> Connect
      </Heading5>
      {children}
    </header>
  );
};
