import { FC } from 'react';
import { ISvgProps } from 'shared/svg/interfaces';

const defaultProps: ISvgProps = {
  viewBox: '0 0 31.334 31.334',
  pathClassName: 'st0',
};

export const SvgIconArrowRight: FC<ISvgProps> = (props) => {
  props = { ...defaultProps, ...props };
  const { pathClassName, ...svgProps } = props;

  return (
    <svg {...svgProps}>
      <path
        className={pathClassName}
        d="M15.667,0C7.029,0,0.001,7.028,0.001,15.667c0,8.64,7.028,15.667,15.666,15.667c8.639,0,15.666-7.027,15.666-15.667
                C31.333,7.028,24.306,0,15.667,0z M18.097,23.047c-0.39,0.393-0.902,0.587-1.414,0.587s-1.022-0.194-1.414-0.587
                c-0.781-0.779-0.781-2.047,0-2.827l2.552-2.553H8.687c-1.104,0-2-0.896-2-2c0-1.104,0.896-2,2-2h9.132l-2.552-2.552
                c-0.781-0.781-0.781-2.047,0-2.828c0.78-0.781,2.048-0.781,2.828,0l7.381,7.381L18.097,23.047z"
      />
    </svg>
  );
};
