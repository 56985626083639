import LanguageSwitcher from 'components/LanguageSwitcher';
import RefreshIcon from '@mui/icons-material/Refresh';

import './_footer.scss';
import { appVersion } from '_version';
import { FC } from 'react';

export const Footer: FC = () => {
  const baseClassName = 'footer';

  const handleHardReload = () => {
    async function handleHardReload(url: string) {
      await fetch(url, {
        headers: {
          Pragma: 'no-cache',
          Expires: '-1',
          'Cache-Control': 'no-cache',
        },
      });
      window.location.href = url;
      // This is to ensure reload with url's having '#'
      window.location.reload();
    }

    handleHardReload(window.location.href);
  };

  return (
    <footer className={baseClassName}>
      <div className="footer-content">
        <LanguageSwitcher />
        <div className="refresh-btn">
          <RefreshIcon onClick={handleHardReload} />
        </div>
      </div>
      <div className="footer-footer">v{appVersion}</div>
    </footer>
  );
};
