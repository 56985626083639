import { FC } from 'react';
import './_main.scss';

type IMainProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
>;

export const Main: FC<IMainProps> = (props) => {
  return <main className={'main'}>{props.children}</main>;
};
