import {
  ChangeEvent,
  FC,
  FormEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';

import axios from 'axios';

import { Button, Form, FormGroup, Loader } from 'components';

import { Alert } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import InputText from 'components/inputs/input-text';
import { TranslatedString } from 'i18n/translations/no';

import './OpenGateForm.scss';

type Props = {
  submitLink: string;
};

/**
 * Ensures correct format by removing all unallowed characters
 * when the user tries to add an un-allowed character
 */
export const ensureLicenseNumberFormat = (value: string): string =>
  value
    /**
     * Removes all empty spaces from value
     */
    .replace(' ', '')
    /**
     * Converts text to upper case
     */
    .toLocaleUpperCase()
    /**
     * Removes everything that is not:
     * - a letter
     * - a norwegian letter
     * - a number
     */
    .replaceAll(/[^A-Z0-9\WÆÅØ]/gi, '');

const OpenGateForm: FC<Props> = ({ submitLink }) => {
  const { t } = useTranslation();
  const [submitError, setSubmitError] = useState<{
    code?: string;
    message: TranslatedString;
  }>();
  const [inputError, setInputError] = useState('');
  const [hasOpenedGate, setHasOpenedGate] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [regNo, setRegNo] = useState('');

  useEffect(() => {
    if (hasOpenedGate) {
      setTimeout(() => {
        setHasOpenedGate(false);
        setRegNo('');
      }, 10000);
    }
  }, [hasOpenedGate]);

  useEffect(() => {
    if (inputError) {
      setTimeout(() => {
        setInputError('');
        setRegNo('');
      }, 10000);
    }
  }, [inputError]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setInputError('');

    if (!submitLink) {
      setIsSubmitting(false);
      setSubmitError({
        code: 'missing-open-link',
        message: 'Gate cannot be opened',
      });
      return;
    }

    axios
      .post(submitLink, {
        RegNo: regNo,
      })
      .then(() => {
        setIsSubmitting(false);
        setHasOpenedGate(true);
      })
      .catch((error) => {
        setHasOpenedGate(false);

        const errorResponse = error.response;
        const statusCode = errorResponse?.status;

        setIsSubmitting(false);
        if (statusCode === 403) {
          setSubmitError({
            code: undefined,
            message: 'Cannot open gate for the given license plate',
          });
        } else {
          setSubmitError({
            code: 'status-code-' + !!statusCode ? statusCode : 'unknown',
            message: 'Cannot open gate for the given license plate',
          });
        }
      });
  };

  const updateValue = (value: string) => {
    console.log('update value called, with value', value);
    setRegNo(value);
    setSubmitError(undefined);

    if (inputError) {
      setInputError('');
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    updateValue(ensureLicenseNumberFormat(value));
  };

  const errorMessage = useMemo(() => {
    if (!submitError) {
      return '';
    }

    let msg = t(submitError.message);
    if (submitError.code) {
      msg += ' [' + submitError.code + ']';
    }
    return msg;
  }, [submitError, t]);

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup formatClassName="dropdown-combo">
        <InputText
          id="registration-number-input"
          label={t('Registration number')}
          value={regNo}
          error={errorMessage ? errorMessage : inputError}
          onChange={handleChange}
          maxLength={8}
          required
        />
        {!!regNo && (
          <div
            className="clear-icon"
            onClick={() => {
              updateValue('');
            }}
          >
            <ClearIcon style={{ fontSize: '40px' }} />
          </div>
        )}
      </FormGroup>
      {hasOpenedGate ? (
        <Alert variant="filled" severity="success">
          {t('Gate successfully opened')}
        </Alert>
      ) : (
        <Button type="submit">
          <Loader loading={isSubmitting}>{t('Submit')}</Loader>
        </Button>
      )}
    </Form>
  );
};

export default OpenGateForm;
