// Need to use the React-specific entry point to allow generating React hooks
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  credentials: 'include',
});

export type ApiLink = {
  Href: string;
  Rel: string;
};

type TerminalData = {
  TerminalGuid: string;
  Name: string;
  Prompt: string;
  Links: ApiLink[];
  PromptImageUri: string;
  UiParameters: {
    backgroundColor?: string;
    textColor?: string;
    isHeadingHidden?: boolean;
    isTerminalNameHidden?: boolean;
  } | null;
};

// Define a service using a base URL and expected endpoints
export const ApiEndpoints = createApi({
  reducerPath: 'apiEndpoints',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    endpoints: builder.query<{ Links: ApiLink[] }, string>({
      query: () => 'byr-access',
    }),
    terminalData: builder.query<TerminalData, string>({
      query: (url) => url,
    }),
  }),
});

export const { useEndpointsQuery, useTerminalDataQuery } = ApiEndpoints;

export default ApiEndpoints;
