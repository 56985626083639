const noJSON = {
  // Languages
  'Language Switcher': 'Språkbytter',
  English: 'Engelsk',
  Norwegian: 'Norsk',
  Swedish: 'Svensk',
  'Total amount (Including Tax)': 'Totale sum (Inkludert Mva)',

  'Go back': 'Gå tilbake',
  'Pay with card': 'Betal med kort',
  'Choose Payment Method': 'Velge betalingsmetode',

  Start: 'Start',
  Continue: 'Fortsette',

  'Registration number': 'Registreringsnummer',
  Submit: 'Send inn',

  'Parking details': 'Parkeringsdetaljer',
  'Send recipe': 'Send oppskrift',
  Home: 'Hjem',

  'All set to go': 'Alle klare til å gå',
  'Payment failed': 'Betaling mislyktes',

  'License plate': 'Lisens plate',

  'Total cost': 'Total cost',
  'Try again': 'Prøv igjen',

  'Parking area': 'Parkeringsplass',
  Duration: 'Varighet',
  'Invoice date': 'Faktura dato',
  Amount: 'Beløp',

  'Access terminal': 'Tilgangsterminal',

  Security: 'Sikkerhet',
  'Enter your registration number': 'Skriv inn registreringsnummeret ditt',
  Next: 'Neste',
  'There is no parking on {{RegistrationNumber}}. Are you sure you have written correctly?':
    'Det er ingen parkering på {{RegistrationNumber}}. Er du sikker på at du har skrevet riktig?',

  'Gate successfully opened': 'Porten ble åpnet',
  'Gate cannot be opened': 'Porten kan ikke åpnes',

  'Cannot open gate for the given license plate':
    'Kan ikke åpne porten for oppgitt skilt',
};

export type TranslatedString = keyof typeof noJSON;

export default noJSON;
