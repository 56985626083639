export interface ISvgProps extends React.SVGProps<SVGSVGElement> {
  pathClassName?: string;
}

export enum SvgIcons {
  CreditCard = 'SvgIconCreditCard',
  CheckCircle = 'SvgIconCheckCircle',
  ArrowRight = 'SvgIconArrowRight',
  Spinner = 'SvgIconSpinner',
  CarBike = 'SvgIconCarBike',
  NeedHelp = 'SvgIconNeedHelp',
}
