import { TextField } from '@mui/material';
import { ChangeEvent, FC } from 'react';

import './_input-text.scss';

type Props = {
  id: string;
  label: string;
  value: string | number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  placeholder?: string;
  type?: 'text' | 'number';

  required?: boolean;
  className?: string;
  maxLength?: number;
};

const InputText: FC<Props> = ({
  id,
  label,
  onChange,
  value = '',
  error = '',
  type = 'text',
  required = false,
  className = 'input-text',
  maxLength = 200,
}) => (
  <TextField
    id={id}
    name={id}
    type={type}
    className={className}
    value={value}
    label={label}
    helperText={error ?? null}
    error={!!error}
    required={required}
    onChange={onChange}
    variant="standard"
    inputProps={{
      maxLength,
    }}
    fullWidth
  />
);

export default InputText;
