interface ISiteConfig {
  pageTitle: string;
  pageTitlePrefix: string;
}

export const siteConfig: ISiteConfig = {
  pageTitle: 'Byr',
  pageTitlePrefix: '-',
};

export default siteConfig;
