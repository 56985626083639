import { Alignment } from 'static/constants';
import { Loader } from 'components';
import { SvgIcon, SvgIcons } from 'shared';
import { FC } from 'react';

interface ISvgCtaContentProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  iconName?: SvgIcons;
  iconWidth?: number;
  iconPosition?: Alignment;
  loading?: boolean;
}

// Anchor tag with button styles/format
export const SvgCtaContent: FC<ISvgCtaContentProps> = (props) => {
  const { iconName, iconPosition, iconWidth, loading, children } = props;

  const leftIcon = iconName && iconPosition === Alignment.Left;
  const rightIcon = iconName && iconPosition === Alignment.Right;

  return (
    <>
      {leftIcon && (
        <Loader loading={loading}>
          <SvgIcon icon={iconName} width={iconWidth} />
        </Loader>
      )}
      {children && <span>{children}</span>}
      {rightIcon && (
        <Loader loading={loading}>
          <SvgIcon icon={iconName} width={iconWidth} />
        </Loader>
      )}
    </>
  );
};
