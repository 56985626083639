import { ApiLink } from '_api/Endpoints/ApiEndpoints';

const getLinkByRel = (links: ApiLink[], rel: string): string | undefined => {
  let i = 0;
  while (i < links.length) {
    const link = links[i];
    i++;
    if (link.Rel === rel) {
      return link.Href;
    }
  }
  return undefined;
};

export { getLinkByRel };
