import { TranslatedString } from './no';

const seJSON: { [key in TranslatedString]: string } = {
  // Languages
  'Language Switcher': 'Språkväxling',
  English: 'Engelsk',
  Norwegian: 'Norsk',
  Swedish: 'Svenska',
  'Total amount (Including Tax)': 'Totalt belopp (inklusive skatt)',

  'Go back': 'Gå tillbaka',
  'Pay with card': 'Betala med kort',
  'Choose Payment Method': 'Välj betalsätt',

  Start: 'Start',
  Continue: 'Fortsätta',

  'Registration number': 'Registreringsnummer',
  Submit: 'Skicka',

  'Parking details': 'Parkeringsdetaljer',
  'Send recipe': 'Skicka recept',
  Home: 'Hem',

  'All set to go': 'Allt klart att köra',
  'Payment failed': 'Betalning misslyckades',

  'License plate': 'Registreringsskylt',

  'Total cost': 'Total kostnad',
  'Try again': 'Försök igen',

  'Parking area': 'Parkeringsplats',
  Duration: 'Varighet',
  'Invoice date': 'Fakturadatum',
  Amount: 'Belopp',

  'Access terminal': 'Åtkomstterminal',

  Security: 'säkerhet',
  'Enter your registration number': 'Ange ditt registreringsnummer',
  Next: 'Nästa',
  'There is no parking on {{RegistrationNumber}}. Are you sure you have written correctly?':
    'Det finns ingen parkering på {{RegistrationNumber}}. Är du säker på att du har skrivit rätt?',

  'Gate successfully opened': 'Porten har öppnats',
  'Gate cannot be opened': 'Porten kan inte öppnas',

  'Cannot open gate for the given license plate':
    'Kan inte öppna grinden för den givna registreringsskylten',
};

export default seJSON;
