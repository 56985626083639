import { Heading2 } from 'components';
import siteConfig from 'hooks/site-config';
import { TranslatedString } from 'i18n/translations/no';
import { useTranslation } from 'react-i18next';
import './_page-heading.scss';
import { FC } from 'react';

interface IPageHeadingProps {
  heading: TranslatedString;
  step?: number | string;
}

// Max steps is set in siteConfig
export const PageHeading: FC<IPageHeadingProps> = (props) => {
  const { t } = useTranslation();
  if (!props) {
    return null;
  }
  const { heading } = props;
  const { pageTitle, pageTitlePrefix } = siteConfig;

  const baseClassName = 'page-heading';
  document.title = `${pageTitle} ${pageTitlePrefix} ${heading}`;

  return (
    <header className={baseClassName}>
      {t(heading) && <Heading2>{heading ?? siteConfig.pageTitle}</Heading2>}
    </header>
  );
};
