export enum ButtonTheme {
  Default = 'default',
  Dark = 'dark',
  Ghost = 'ghost',
}

export enum AnchorTheme {
  Default = 'default',
}

export enum Alignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom',
}

export enum Pages {
  AccessTerminal = 'access-terminal',
}

export enum Countries {
  NOR = 'NOR',
  SWE = 'SWE',
}

export const countries = [
  {
    label: Countries.NOR,
    value: Countries.NOR,
  },
  {
    label: Countries.SWE,
    value: Countries.SWE,
  },
];
