import classNames from 'classnames';
import './_heading2.scss';
import { FC } from 'react';

interface IHeading2Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  > {
  formatClassName?: string;
}

export const Heading2: FC<IHeading2Props> = (props) => {
  if (!props || !props.children) {
    return null;
  }
  const { className, formatClassName, children, ...heading2Props } = props;

  const baseClassName = 'heading2';
  const componentClassName = classNames(
    className,
    baseClassName,
    formatClassName,
  );

  return (
    <h2 {...heading2Props} className={componentClassName}>
      {children}
    </h2>
  );
};
