import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import './_page.scss';

/* type IPage = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>; */

type PageProps = {
  className?: string;
  children: ReactNode | ReactNode[];
};
export const Page: FC<PageProps> = (props) => {
  const { className, children } = props;

  const baseClassName = 'page';
  const componentClassName = classNames(baseClassName, className);

  return <section className={componentClassName}>{children}</section>;
};
