import { Header, Main } from 'components';
import { Footer } from './Footer/footer';
import { FC } from 'react';

type ILayoutProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
>;

const Layout: FC<ILayoutProps> = (props) => {
  return (
    <>
      <Header />
      <Main>{props.children}</Main>
      <Footer />
    </>
  );
};
export default Layout;
