import { FC, useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { Loader, Page, PageHeading } from 'components';

import useEndpointsQuery from '_api/Endpoints';
import { getLinkByRel } from 'helpers/link-by-rel';
import { useTerminalDataQuery } from '_api/Endpoints/ApiEndpoints';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Alert } from '@mui/material';
import { parseTemplate } from 'url-template';

import OpenGateForm from 'components/open-gate-form';

import './access-terminal-page.scss';

/**
 * on open-gate:
 * 204 -> success
 * 400 -> license plate number wrong (input validation) + message 400 almost always with error message
 * 404 -> terminal cannot be found
 * 403 -> license plate matches pattern, but no match in the car park
 * 500 -> no camera connected
 */
const AccessTerminalPage: FC = () => {
  const { data: endpoints } = useEndpointsQuery('');
  const [searchParams] = useSearchParams();
  const terminalguid = searchParams.get('terminalGuid');

  const [terminalLink, setTerminalLink] = useState<undefined | string>();
  const [submitLink, setSubmitLink] = useState<undefined | string>();

  useEffect(() => {
    if (!terminalguid) {
      return;
    }
    const links = endpoints?.Links;

    if (links) {
      const link = getLinkByRel(links, 'access-terminal');

      if (link) {
        const urlTemplate = parseTemplate(decodeURI(link));

        setTerminalLink(
          urlTemplate.expand({
            terminalguid: terminalguid,
          }),
        );
      }
    }
  }, [endpoints, terminalguid]);

  const { data: terminalData, error: terminalDataError } = useTerminalDataQuery(
    terminalLink ?? skipToken,
  );

  useEffect(() => {
    if (terminalDataError) {
      setIsLoading(false);
    }
    if (terminalData) {
      if (terminalData?.UiParameters) {
        const { textColor, backgroundColor } = terminalData.UiParameters;
        if (!!backgroundColor && CSS.supports('color', backgroundColor)) {
          document.body.style.background = backgroundColor;
        }
        if (!!textColor && CSS.supports('color', textColor)) {
          document.body.style.color = textColor;
          const root = document.documentElement;
          root.style.setProperty('--dark', textColor);
          root.style.setProperty('--default-border', textColor);
          root.style.setProperty('--default-contrast', textColor);
        }
      }

      setIsLoading(false);
      if (terminalData?.Links) {
        setSubmitLink(getLinkByRel(terminalData.Links, 'open-gate'));
      }
    }
  }, [terminalData, terminalDataError]);

  const [isLoading, setIsLoading] = useState(true);

  const baseClassName = 'access-terminal-page';

  return (
    <Page className={baseClassName}>
      {terminalData?.UiParameters?.isHeadingHidden !== true && (
        <PageHeading heading="Access terminal" />
      )}

      {!terminalguid ? (
        <Alert variant="outlined" severity="error">
          Terminal id must be provided in the url
        </Alert>
      ) : (
        <>
          {isLoading ? (
            <Loader loading={true} className="loader page-loading" />
          ) : (
            <>
              {terminalData ? (
                <>
                  {terminalData?.UiParameters?.isTerminalNameHidden !==
                    true && <p>{terminalData.Name}</p>}

                  {!!terminalData.Prompt && <p> {terminalData.Prompt}</p>}

                  {submitLink ? (
                    <OpenGateForm submitLink={submitLink} />
                  ) : (
                    <Alert variant="outlined" severity="error">
                      {String(
                        "Terminal cannot be used for opening gate because 'open-gate' link is not available",
                      )}
                    </Alert>
                  )}
                  {!!terminalData.PromptImageUri && (
                    <img
                      width="100%"
                      src={terminalData.PromptImageUri}
                      alt="terminal-pic"
                    />
                  )}
                </>
              ) : (
                <Alert variant="outlined" severity="error">
                  Could not get data for terminal id {terminalguid}
                </Alert>
              )}
            </>
          )}
        </>
      )}
    </Page>
  );
};

export default AccessTerminalPage;
