import { FC } from 'react';
import { ISvgProps } from 'shared/svg/interfaces';

const defaultProps: ISvgProps = {
  viewBox: '0 0 65 64',
  width: 65,
  height: 70,
};

export const SvgIconNeedHelp: FC<ISvgProps> = (props) => {
  props = { ...defaultProps, ...props };
  const { pathClassName, ...svgProps } = props;

  return (
    <svg {...svgProps}>
      <path
        className={pathClassName}
        d="M56.375 0.125H8.375C3.875 0.125 0.375 3.625 0.375 8V43.875C0.375 48.25 3.875 51.75 8.375 51.75H20.375V62.25C20.375 63.125 21.125 63.75 21.875 63.75C22.125 63.75 22.5 63.625 22.75 63.375L38.375 51.75H56.375C60.75 51.75 64.375 48.125 64.375 43.875V8C64.375 3.625 60.875 0.125 56.375 0.125ZM62.375 44C62.375 47.25 59.625 50 56.375 50H37.625L22.375 61.5V50H8.375C5 50 2.375 47.25 2.375 44V8C2.375 4.75 5 2.125 8.375 2.125H56.375C59.625 2.125 62.375 4.75 62.375 8V44ZM40.5 22C41.5 22 42.375 21 42.375 20C42.375 18.875 41.5 18 40.375 18C39.375 18 38.375 19 38.375 20C38.375 21 39.375 22 40.5 22ZM43.625 31.5C41.25 35.625 37 38 32.5 38C27.875 38 23.625 35.625 21.25 31.5C21 31.125 20.375 30.875 19.875 31.25C19.5 31.5 19.25 32.125 19.625 32.5C22.25 37.125 27.125 40 32.375 40C37.5 40 42.5 37.125 45.25 32.5C45.5 32.125 45.25 31.5 44.875 31.125C44.375 30.875 43.875 31.125 43.625 31.5ZM24.5 22C25.5 22 26.375 21 26.375 20C26.375 18.875 25.5 18 24.375 18C23.25 18 22.375 18.875 22.375 19.875C22.375 20.875 23.375 22 24.5 22Z"
      />
    </svg>
  );
};
